export default { 
  // 长的描述文案
  messages: {
    noComponent: 'The corresponding component was not found',
    visibleAddInterface: 'visible in the add interface',
    editAddInterface: 'editable in the add interface',
    visibleEditInterface: 'visible in the edit interface',
    editInEditInterface: 'editable in the edit interface',
    visibleListInterface: 'visible in the edit interface',
    invisibleAndDefault: 'invisible and has a default value',
    batchModifyVisible: 'modify visibility in bulk',
  },
  // 短语文案
  tips: {
    alert: 'alert',
    warning: 'warning',
    error: 'error',
    success: 'success',
    index: 'index',
    summation: 'summation',
    total: 'total',
    page: 'page',
    more: 'more',
    to: 'to',
    of: 'of',
    next: 'next',
    previous: 'previous',
    loading: 'loading',
    selectAll: 'select all',
    search: 'search',
    null: 'null',
    equal: 'equal',
    notEqual: 'not equal to',
    exclude: 'exclude',
    lessThanOrEqual: 'less than or equal to',
    greaterOrEqual: 'greater or equal to',
    inRange: 'within the scope',
    lessThan: 'less than',
    greater: 'greater',
    contain: 'contain',
    startWith: 'start with',
    endWith: 'end with',
    noData: 'no data',
    groupBy: 'group by',
    ungroupBy: 'ungroup by',
    expandAll: 'expand all',
    collapseAll: 'collapse all',
    toolPanel: 'tool panel',
    export: 'export',
    csvExport: 'CSV export',
    excelExport: 'Excel export',
    pinLeft: 'pin left',
    pinRight: 'pin right',
    unpin: 'no pin',
    sum: 'sum',
    minimum: 'minimum',
    max: 'max',
    avg: 'average',
    average: 'average value',
    copy: 'copy',
    paste: 'paste',
    uploadAttachment: 'upload attachment',
    clear: 'clear',
    fullscreen: 'fullscreen',
    exitFullscreen: 'exit fullscreen',
    exit: 'exit',
    default: 'default',
    sina: 'sina',
    link: 'link',
    insertCode: 'insert code',
    insertTable: 'insert table',
    editTable: 'edit table',
    insertVideo: 'insert video',
    editPicture: 'edit picture',
    uploadImage: 'upload image',
    webImage: 'web image',
    find: 'search',
    yes: 'yes',
    no: 'no',
    label: 'label',
    operator: 'operator',
    pleaseSelect: 'please select',
    button: 'button',
    modal: 'modal',
    request: 'request',
    route: 'route',
    expand: 'expand',
    fold: 'fold',
    all: 'all',
    ascending: 'ascending',
    add: 'add',
    modify: 'modify',
    delete: 'delete',
    submit: 'submit',
    cancelSubmission: 'cancel submission',
    invalid: 'invalid',
    copy: 'copy',
    import: 'import', 
    export: 'export',
    print: 'print',
    scope: 'scope',
    type: 'type',
    fontWeight: 'font weight',
    fontStyle: 'font style',
    textDecoration: 'text decoration',
    backgroundColor: 'background color',
    color: 'color',
    rule: 'rule'
  },
  // ag表格(具有通用性的文案)
  table: {
    currentGroup: 'current group',
    allColumns: 'all columns',
    perspectiveColumn: 'perspective column',
    dropGroupColumnHere: 'Drag and drop the "columns" that need to be grouped and searched here',
    aggregateColumn: 'aggregate column',
    perspectiveMode: 'perspective mode',
    groupDetails: 'group details',
    aggregateAnalysisValue: 'aggregate analysis value',
    dropAggregatedGroupColumnHere: 'Drag and drop the "columns" that need to be aggregated and analyzed here',
    dropPerspectiveGroupColumnHere: 'Drag and drop the "column" that needs perspective analysis to here',
    pinnedColumn: 'pin column',
    aggregateValue: 'aggregate value',
    autosizeThiscolumn: 'autosize this column',
    autosizeAllColumns: 'autosize all column',
    resetColumns: 'Reset all column position information',
    firstValue: 'first value',
    lastValue: 'last value',
    count: 'count',
    copyWithHeaders: 'copy with headers',
    showAllColumns: 'show all columns',
    hideCurrentColumn: 'hide current column',
    columnNameConflict: 'column name conflict',
  },
  docFile: {
    importingFile: 'importing file',
    uploadFileLimit: 'Only {total} files can be uploaded',
    fileCanNotUpload: 'Does not support uploading {file}',
    continueDeleteFile: 'This operation will permanently delete the file, do you want to continue?',
    selectUpToFile: 'Select up to {total} files',
    fileTooLarge: 'File content is too large',
    pictureTypeLimit: 'The picture type must be one of .gif, jpeg, jpg, png',
  },
  // 富文本
  wangEditor: {
    enterVideoAddress: 'Please enter the video address',
    insertPictureError: 'insert picture error',
    imageVerificationFailed: 'image verification failed',
    uploadLimitAtATime: 'Upload up to {total} pictures at a time',
    uploadPictureTimeOut: 'upload picture time out',
    uploadImageError: 'upload image error',
    uploadPictureFailed: 'upload picture failed',
    uploadErrorMsg: 'Uploaded picture returns wrong result, return result is ',
    initParamsError: 'Error: No parameters were passed in when initializing the editor, please refer to the documentation',
    runInBrowser: 'Please run in a browser environment',
    setTitle: 'set title',
    textColor: 'text color',
    backgroundColor: 'background color',
    alignment: 'alignment'
  },
  // 扩展属性
  extensionProperty: {
    extendAttrConfig: 'extended attribute configuration',
    notSupportedAttr: 'Unsupported extended attributes: [{attr}], please verify before operating.',
    enterJSONForm: 'please enter a string in json form',
    enterConfig: 'enter configuration',
    noConfiguration: 'no configuration',
    extendAttribute: 'extended attributes',
    displayControl: 'display control',
    readWritePrintRule: 'read-write printing rules',
    readWriteDisplay: 'reading and writing rules display control',
    readWriteRule: 'read and write rules',
    expansionComponent: 'expansion component',
    conditionField: 'condition field',
    controlColumnField: 'the field of the control list operation column button',
    continueChange: 'After changing the condition field, the content of the configured rule configuration table will be cleared. Do you want to continue this operation?',
    pleaseEnter: 'please enter',
    separatedByCommas: 'separated by commas',
    JSONFormatError: 'JSON format error',
    displayAreaField: 'display area field',
    enterNameInTable: 'Please enter the name in the table',
    sourceField: 'source field',
    sourceFieldOptionGroup: 'source field option group',
    enterName: 'please enter a name',
    nameInTable: 'name in the table',
    dragSort: 'Long press to drag sort',
    buttonName: 'button name',
    enterButtonName: 'please enter the button name',
    field: 'field',
    value: 'value',
    condition: 'condition',
    enterValueWithComma: 'please enter a value (if multiple values are used, please separate them with a comma)',
    displayName: 'display name',
    selectSourceField: 'please select the source field',
    correspondence: 'correspondence',
    enterOptionKey: 'enter the key of the option',
    enterOptionValue: 'enter the value of the option',
    enterSourceField: 'please enter the source field',
    correspondenceIsRequired: 'please fill in at least one correspondence',
    assignValues: 'assign values to other fields [formRequest]',
    selectKnownField: 'please select the known condition field',
    enterApi: 'please enter a custom interface, for example /p/c/test',
    confirmFieldAndAAddress: 'please confirm whether the source field and interface address are filled in',
    subtable: 'subtable',
    selectField: 'please select a field',
    enterValue: 'please enter the value',
    associateSubtable: 'There are no sub-tables available for the time being, please associate the sub-tables first',
    associateField: 'associate field',
    contrastValue: 'contrast value',
    selectOtherField: 'The same field already exists, please select again',
    buttonField: 'please enter the button field name',
    correspondingValue: 'corresponding type value',
    optionalPrompt: 'prompt information (optional)',
    triggerType: 'trigger event type',
    confirmEventAndValue: 'Please confirm whether to fill in the trigger event type and the value of the corresponding type',
    encryptionMethod: 'encryption method',
    hideAll: 'hide all',
    customHide: 'custom hide',
    frontHiddenDigits: 'front hidden digits',
    endHiddenDigits: 'hidden digits at the end',
    leftColumnTable: 'left pinned column of table',
    rightColumnTable: 'right pinned column of table',
    selectLeftColumn: 'please select left pinned column',
    selectRightColumn: 'please select right pinned column',
    filterBaseOnField: 'filter based on multiple fields',
    ifFieldOnMainTable: ' whether the known condition field is on the main table',
    knownConditionField: 'known condition field',
    filteredFieldInForeignKey: 'fields that need to be filtered',
    ifSourceOnMainTable: 'whether the source field is on the main table',
    confirmConditionAndFilter: 'please confirm whether to fill in the condition field and filter field',
    targetFieldEditable: 'whether the target field is not editable',
    ifFieldRequired: 'is the target field required',
    clearConfig: 'clear this configuration',
    selectRequiredOrUneditable: 'whether the field is required and uneditable, enable at least one of them',
    confirmFieldFilled: 'please confirm whether the fields are filled in',
    selectQueryConditions: 'please select query conditions',
    selectTable: 'please select a table',
    onlySelectType: 'Only allow the display type to be select, and the fields of the associated table can be configured. Please check whether the current field meets the conditions',
    checkCondition: 'Please check whether the three options of the condition {row} are filled with valid value',
    ifEncrypt: 'whether to encrypt',
    encryptDisplay: 'the encrypted value is displayed',
    valueFilter: 'value filtering',
    foreignKeyRelatedField: 'foreign key related fields',
    associatedFieldHeader: 'the associated field is in the header',
    HRFold: 'HR folding',
    expandAccordToField: 'Determine whether the hr field is expanded or collapsed by default according to the configuration. If there is no configuration, the default is [Expand]',
    dateType: 'date type',
    beforeWithToday: 'before (including today)',
    afterWithToday: 'after (including today)',
    beforeWithoutToday: 'before (excluding today)',
    afterWithoutToday: 'after (excluding today)',
    controlDateRange: 'optional range of control date field',
    linkCustomView: 'field link customization interface',
    customViewAddress: 'customized interface address',
    popupType: 'popup type',
    associatedFieldName: 'associated field name',
    windowName: 'window name',
    associatedTable: 'associated table',
    routeId: 'route ID (not repeatable in the system)',
    customComponent: "custom components (effective when the 'objdistype' value is 'defined')",
    listSpecifiedField: 'Links are displayed in the specified fields on the list interface, click to enter the corresponding interface. (Support tabpanle, object, popwin, customized, link)',
    virtualCalculatedColumn: 'virtual field - calculated column',
    calculationExpression: 'calculation expression',
    fieldList: 'field list',
    dateFormat: 'date format',
    assignDescription: 'Assignment method is calculated column, obtained through other fields and calculation formula',
    listTranslation: 'foreign key, virtual field list translation',
    objectTranslation: 'foreign key, virtual field single object translation',
    fillPath: 'fill in the path of the conversion display format',
    fieldHide: 'field hide or show',
    influenceColumn: 'influence column',
    influenceValue: 'influence value',
    displayValue: 'display value',
    ifExecHide: 'whether to execute hidden logic',
    keepFieldPosition: 'keep the position of the field when hiding it',
    determineShow: 'Determine whether field B is displayed according to the value of field A (if field A is a foreign key type and needs to be matched based on the displayed value, match is configured as label, and if it is not configured, the default is value)',
    linkageCalculation: 'interface linkage calculation',
    relatedField: 'related field',
    linkageField: 'linkage field',
    linkageDescription: 'With the values entered in the A and B fields, the value of the C field will change instantly, and C is the linkage field',
    intervalConfiguration: 'interval configuration',
    upstreamField: 'upstream restriction field',
    downstreamField: 'downstream restriction field',
    intervalDescription: 'The interval field is a virtual field and only supports a single object',
    ifFilterField: 'Whether to filter unavailable data',
    onlyISACTIVE: 'Only for the ISACTIVE field in the associated table, editable or uneditable, but the table is invalid',
    cachedTransition: 'whether the translation is cached',
    cacheRule: 'If not configured, the default is true, if configured as false, no caching will be performed',
    cacheTime: 'field cache time configuration',
    cacheDescription: 'field cache time setting, in seconds',
    permissionConfiguration: 'list data permission configuration',
    dataPermissionSign: 'data permission sign',
    fieldInSourceTable: 'field in the source table',
    getEditPermission: 'whether to obtain editing permissions',
    permissionDescription: 'Data permissions of the foreign key field drop-down list in the query condition area of the list interface',
    singlePermissionConfiguration: 'single object data permission configuration',
    fieldInThisTable: 'corresponding fields of this table',
    singlePermissionDescription: 'Data permission of foreign key field drop-down list in single object interface',
    storePermissionConfiguration: 'store data permission configuration',
    masterAuthority: 'main authority (store warehouse) field',
    storePermissionDescription: 'Control the data permissions of the drop-down list of the foreign key field associated with the single object interface (store warehouse)',
    listQueryRule: 'list query rule',
    matchRule: 'matching rule',
    fullMatch: 'full match',
    leftMatch: 'left match',
    rightMatch: 'right match',
    fullBlur: 'full blur',
    queryIsRequired: 'is it required when inquiring',
    enableVerification: 'enable unique verification',
    verificationDescription: 'When the form is added, when the filled value loses the focus in the text box, check whether the table already exists in the table / the text box loses the focus to verify the uniqueness',
    isdefaultField: "'isdefault' field",
    isdefaultDescription: "'isdefault' field configurable item",
    clearWhenHidden: 'clear field when hiding',
    clearDescription: "Fields with 'hidecolumn' extended attributes are configured, and the contents of the fields are automatically cleared when hidden",
    linkJump: 'configure the link to jump',
    linkJumpDescription: 'In the remarks of the field, you can click the link to jump, such as https://www.baidu.com/. Note: [Display Remarks] needs to be checked in the field configuration',
    enterLink: 'please enter the link address',
    forbiddenNegativeSign: 'prohibit entering negative signs',
    forbiddenNegativeSignDesc: 'control numeric fields cannot enter negative signs',
    uploadNumber: 'number of pictures uploaded',
    uploadNumberDesc: 'control the number of pictures uploaded',
    enterNumber: 'please enter a number',
    fileUploadNumber: 'number of files uploaded',
    fileUploadNumberDesc: 'control the number of files uploaded',
    fileUploadSize: 'single file upload size',
    fileUploadSizeDesc: 'Control the upload size of a single file',
    fileUploadFormat: 'file upload format',
    fileUploadFormatDesc: 'For the "upload attachment" component, you can control the upload of fixed format files. Multiple file formats can be configured and are not case sensitive, separated by English commas, such as png, crd, txt, AI, pdf, JSON. If not configured, files in all formats can be uploaded.',
    enterFileFormat: 'please enter the file format',
    setInputPrompt: 'set input prompt',
    setInputPromptDesc: 'After configuring the prompt content in the customizable input text box, the query field in the list interface and the single-object interface can be effective. If it is not configured, the system default prompt content will be displayed: "Please enter XX", "Please select XX".',
    enterPrompt: 'please enter the prompt',
    maximumChar: 'maximum number of characters',
    maximumCharDesc: 'Control the maximum number of words displayed in the non-editable field of the detailed list',
    switchApi: 'interface called by switch',
    switchApiDesc: 'List configuration switch components, click the switch button to call the interface url',
    expansionComponentDesc: 'Currently supported types: 1. YearMonth (date component of year and month) 2. String (component that supports displaying plain text and user-customized html content) 3. MonthDay (date component of day and month).4.InputWithSelect(conditional query input box) Note: Only when the [Display Control] field value is text or textarea, YearMonth and MonthDay can be configured.2. When the field type is string, and the assignment method is normal assignment, InputWithSelect can be configured',
    componentStyle: 'component style',
    stylePlaceholder: 'For example color:red;',
    filterConfig: 'filter configurable items',
    filterConfigDesc: "Field extension attributes filter configurable items according to the assignment method. Add 'supportType' to the corresponding configuration file",
    staticRule: 'static rule',
    staticRuleDesc: 'According to the value of the source field, control whether the target field is editable and required. Note: At least one of the fields must be filled in and not editable',
    passMutiFields: 'pass multiple fields',
    passMutiFieldsDesc: 'The foreign key drop-down query interface passes multiple field conditions, and multiple sets of condition fields can be configured (the meaning is similar to the associated filter)',
    assignValueDesc: 'Assign values to other fields in the interface according to the value of the field',
    filterBaseOnFieldDesc: 'Foreign key related fields are filtered based on multiple source fields. Note: When the source field comes from the main table, the main table name needs to be added before the field name, and the format is "main table name.field name"',
    columnWidth: 'the width of the table column',
    columnWidthDesc: 'Configure the fixed width of the column where the table field is located',
    groupFilter: 'field option group value association filter',
    groupFilterDesc: 'For fields in the form of a two-field option group, the options of the target field are filtered according to the option value of the source field.',
    hideLabel: 'hide the label of form item',
    hideLabelDesc: 'Control the display and hiding of the label of the query area form',
    listDefaultSort: 'the list is sorted by default',
    fieldName: 'field name',
    sortDesc: 'After entering the list interface, the default sorting method of the data in the list is configured',
    globalSearchField: 'global search field',
    fuzzyMatching: 'fuzzy matching',
    rightBlur: 'right blur',
    leftBlur: 'left blur',
    globalAutoSearch: 'global search for automatic matching fields',
    middleField: 'middle field',
    leftField: 'left field',
    multiPopDesc: 'Multi-selection configuration of complex pop-up windows',
    fuzzyDesc: 'The user manually enters for fuzzy matching, and the columns for fuzzy matching are arranged in order',
    foreignDropdownList: 'foreign key drop-down list',
    sortInfo: 'sorting information',
    dropdownListColumn: 'drop-down list display column',
    dropdownListDesc: 'Foreign key related fields, drop-down window, specify the displayed column, specify the column used for data sorting',
    enterFieldQuery: 'enter key query field',
    fieldQueryDesc: 'The field designated as AK2 can be used for query in the column name interface',
    hiddenExportInDetail: 'details page hidden export',
    hiddenExportDesc: 'The data of the table is written by custom service query, not standard data. If export is not supported, the export button can be hidden',
    foreignOptional: 'optional foreign key field',
    foreignOptionalDesc: 'After configuration, the foreign key related fields in the imported template can be selected according to the drop-down selection of the records in the related table',
    buttonDisplay: 'button display',
    displayCondition: 'display condition',
    buttonDisplayDesc: 'Single object supports specifying whether the button is displayed in different scenes',
    readOnlyStatus: 'read-only status',
    readOnlyStatusDesc: 'The value control data of a certain field of the table is read-only and cannot be edited',
    watermarkPriority: 'watermark priority',
    watermarkPriorityDesc: 'Configure the field name directly, the higher the priority of the field',
    mergeQuery: 'state merge query',
    mergedQueryField: 'the merged query field name',
    mergeField: 'merge field',
    mergeQueryDesc: 'Combine the values of multiple drop-down options status fields into one query field for display in the list interface',
    stylePriority: 'style priority',
    stylePriorityDesc: 'Configure the priority of the list style, directly configure a>b>c, where a, b, c are the field names',
    displayRows: 'display the number of lines',
    displayRowsDesc: 'The foreign key association drop-down list shows the number of rows per page',
    showAction: 'display action button',
    showActionDesc: 'A new single object can display the action definition buttons that meet the display conditions',
    listCustomQuery: 'list custom query',
    serverName: 'server name',
    listCustomQueryDesc: 'The data of the table is written by the custom service query, not the standard data',
    treeViewConfig: 'tree interface configuration',
    treeView: 'tree interface',
    treeViewConfigDesc: 'List interface uses tree interface',
    listDataPermission: 'list data permission',
    listDataPermissionDesc: 'Control the data filtered by the list interface, and the data that can be selected by the query conditions of the list interface (only check the read permission)',
    detailDataPermission: 'single object data permission',
    detailDataPermissionDesc: 'Whether the control list object interface can be edited (only check the write permission)',
    setNormalList: 'set as normal list',
    setNormalListDesc: 'Set the list to a normal list (not ag-grid table)',
    enableDynamicRoute: 'enable dynamic routing',
    enableDynamicRouteDesc: 'Realization effect: double-click the detailed record in the table, then the parameter OBJID will be jumped to the single object interface of the table stored in TABLENAME',
    displayTemporaryStorageButton: 'temporary storage button display',
    openTemporaryStorageService: 'whether to open the temporary storage service',
    generalApi: 'general interface',
    displayTemporaryStorageDesc: 'Realization effect: The single-object interface displays the [temporary save] button, and the front end does not do the required verification when clicked',
    hideMainTable: 'hide the main table',
    hideMainTableDesc: 'Realization effect: only for single objects with left and right structure, and when the first sub-table is a customized interface (detailed menu), the main table can be hidden',
    hideImport: 'hide import button',
    hideImportDesc: 'Realization effect: hide the import button of the standard list interface',
    hideExport: 'hide export button',
    hideExportDesc: 'Realization effect: hide the export button of the standard list interface',
    storeQuery: 'Store query conditions',
    storeQueryDesc: 'Realization effect: After the standard list interface is inquired according to the query conditions, when the page is closed and reopened, the last query conditions will be stored for a certain period of time (default one day). Note: This configuration is only valid when the switch in the front-end configuration is turned off (if the front-end switch is turned on, it will be stored if it is not configured; and the configuration false is invalid at this time). Multi-select pop-ups and state merge queries will not be stored; query fields with default values, even if the page is opened after modifying the conditions, the default values of the settings will be displayed first.',
    tabConfig: 'list query tab configuration',
    tabConfigDesc: 'Increase the query tab page configuration of the list. Validity rule of the field: the tag name exists + at least one valid condition under the tag (associated field + operator + comparison value in the same row have values), it is regarded as valid data. PS: The tag group supports long-press and drag-and-drop sorting',
    billMarkConfig: 'bill mark configuration',
    billMarkConfigDesc: 'Increase the configuration of the bill mark. Field validation rules: If the display area field exists + there is at least one valid condition under the display area field (the source field + source field option group in the same row has a value), it is regarded as valid data. PS: Each display area field group supports long-press and drag-and-drop sorting',
    buttonDisableCondition: 'button disable condition',
    buttonDisableConditionDesc: 'Add the configuration of button disable condition',
    queryPreOperation: 'list query pre-operation',
    queryPreOperationDesc: 'Support the configuration before the query conditions of the table extended attributes. The currently available fields are: text',
    tablePinnedColumn: 'table pinned column',
    tablePinnedColumnDesc: 'Set the display of fixed columns in the table. The format returned by this configuration is xx|xx, where the field on the left of the vertical line represents the column fixed on the left, and the field on the right of the vertical line represents the column fixed on the right.',
    subTableDisplay: 'control subtable display',
    subTableDisplayDesc: 'When the main table field is equal to a certain value, hide the specified subtable',
    conditionFilter: 'The B field filters out the data under the corresponding conditions according to the value selected by the A field',
    DateShortcut: 'date shortcut',
    DateShortcutDesc: 'Set the shortcut options of the date component',
    enterDateDesc: 'enter a date description',
    rowToColumn: 'row to column',
    dynamicDisplayTable: 'Dynamic display header, field name',
    sourceTable: 'source table',
    highlightConfig: 'field highlight',
    highlightConfigDesc: 'Field values that satisfy the rules will be highlighted on the interface',
    setStyle: 'set style',
    expressionPlaceholder: 'expressions, for example: v>1 && v<10',
    all: 'all',
    isNull: 'is null',
    isNotNull: 'not null',
    defaultSelected: 'default selected',
    equal: 'equal',
    resolveJson: 'split json',
    resolveJsonDesc: 'Split a field into multiple displays based on the json returned by the field'
  },
  // 图片预览
  previewPicture: {
    picturePreview: 'picture preview'
  },
  // pop-mutiple-choice
  popMultipleChoice: {
    filterResults: 'filter results',
    globalSearch: 'global search',
    viewSelectedResults: 'view selected results',
    searchResult: 'search result',
    serialNumber: 'serial number',
    recordInList: 'The record is already in the selected list',
    requiredTemplateName: 'Template name cannot be empty',
    templateSaved: 'Template saved successfully',
    beSelected: 'selected',
    selectedData: '{total} pieces of data have been selected',
  },
  // imageUpload
  imageUpload: {
    deleteImg: 'This operation will permanently delete the picture, do you want to continue?',
    noPicturePreview: 'no picture preview'
  },
  // mop-multi-select 
  mopMultiSelect: {
    filterResults: 'filter results',
    globalSearch: 'global search',
    viewSelectedResults: 'view selected results',
    searchResult: 'search result',
    serialNumber: 'serial number',
    recordInList: 'The record is already in the selected list',
    requiredTemplateName: 'Template name cannot be empty',
    templateSaved: 'Template saved successfully',
    beSelected: 'selected',
    selectedData: '{total} pieces of data have been selected',
    multiplePop: 'multiple selection in pop-up window',
    moreFilters: 'more filters',
    import: 'import', 
  },
  // 图标选择器
  iconfontPicker: {
    internal: 'internal',
    color: 'color',
    confirm: 'confirm',
    placeholder: 'please enter the class name of the icon',
    tip: 'please make sure the font icon library is imported'
  }
}