var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classFixName }, [
    _vm.docList.valuedata &&
    (_vm.docList.valuedata.length > 0 || _vm.docList.valuedata.url)
      ? _c("div", [
          _c(
            "ul",
            { class: _vm.classFixName + "-list" },
            _vm._l(_vm.docList.valuedata, function (option, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "dragging",
                      rawName: "v-dragging",
                      value: {
                        item: option,
                        list: _vm.docList.valuedata,
                        group: option,
                      },
                      expression:
                        "{ item: option, list: docList.valuedata,group:option  }",
                    },
                  ],
                  key: index,
                },
                [
                  _vm.getDocFileWebConf
                    ? _c(
                        "a",
                        {
                          attrs: { target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadUrlFile(
                                _vm.getDocFileWebConfUrl + "?url=" + option.url
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(option.name))]
                      )
                    : _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.urlChange(option.url)
                            },
                          },
                        },
                        [_vm._v(_vm._s(option.name))]
                      ),
                  _vm._v(" "),
                  _vm.docList.readonly !== true && option.name
                    ? _c("i", {
                        staticClass: "iconfont iconios-close-circle-outline",
                        on: {
                          click: function ($event) {
                            return _vm.deleteLi(index, option)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      { ref: "file" },
      [
        _c(
          "label",
          { class: _vm.docList.readonly ? _vm.classFixName + "-disabled" : "" },
          [
            _c("i", {
              staticClass: "iconfont iconmd-cloud-upload",
              attrs: { "data-target-tag": "fkIcon" },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "file",
                multiple: "multiple",
                disabled: _vm.docList.readonly,
                accept: _vm.accept,
              },
              on: {
                change: function ($event) {
                  $event.stopPropagation()
                  return _vm.uploadFileChange($event)
                },
              },
            }),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("tips.uploadAttachment")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.percent
          ? _c("span", { staticClass: "proInfo" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("docFile.importingFile")) +
                  "……\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.percent
          ? _c("Progress", {
              attrs: {
                percent: _vm.uploadProgress,
                status: _vm.uploadProgress === 100 ? "success" : "active",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }