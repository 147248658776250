var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.prefixClass },
    [
      _c("div", { staticClass: "current-icon" }, [
        _vm.pickerValue.icon
          ? _c("i", {
              class: "iconfont " + _vm.pickerValue.icon,
              style: { color: _vm.pickerValue.color },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "icon-picker-placeholder",
          class: [_vm.pickerValue.icon ? "" : "gray"],
        },
        [_vm._v(_vm._s(_vm.inputDisplayValue))]
      ),
      _vm._v(" "),
      _c(
        "AutoComplete",
        {
          ref: "autoRef",
          attrs: {
            transfer: "",
            disabled: _vm.disabled,
            icon: _vm.inputIcon,
            placeholder: "",
          },
          on: { "on-focus": _vm.handleFocus, "on-blur": _vm.handleBlur },
        },
        [
          _c("div", { ref: "iconPanelRef", staticClass: "icon-picker-panel" }, [
            _c(
              "div",
              { staticClass: "icon-picker-header" },
              _vm._l(_vm.iconTypes, function (item) {
                return _c(
                  "div",
                  {
                    key: item.value,
                    class: [
                      "icon-type",
                      _vm.currentType === item.value ? "selected" : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.changeType(item.value)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "icon-picker-body" }, [
              _vm.currentType === "internal"
                ? _c(
                    "div",
                    {
                      staticClass: "icon-list",
                      style: { width: _vm.contentWidth },
                    },
                    _vm._l(_vm.iconList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.icon,
                          staticClass: "icon-box",
                          class: [item.isSelected ? "selected" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.selectIcon(item)
                            },
                          },
                        },
                        [_c("i", { class: "iconfont " + item.icon })]
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    {
                      staticClass: "icon-list",
                      style: { width: _vm.contentWidth },
                    },
                    [
                      _c("div", { staticClass: "icon-link-wrap" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.iconfont.cn/home/index",
                              target: "_blank",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/iconfontLogo.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "icon-tip" }, [
                        _vm._v(
                          "\n            *" +
                            _vm._s(_vm.$t("iconfontPicker.tip")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-icon-wrap" },
                        [
                          _c("Input", {
                            attrs: {
                              clearable: "",
                              size: "small",
                              placeholder: _vm.$t("iconfontPicker.placeholder"),
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function ($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "Button",
                            {
                              staticClass: "icon-confirm",
                              attrs: { type: "text", size: "small" },
                              on: { click: _vm.confirm },
                            },
                            [_vm._v(_vm._s(_vm.$t("iconfontPicker.confirm")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "icon-picker-footer" },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("iconfontPicker.color")) + ":"),
                ]),
                _vm._v(" "),
                _c("ColorPicker", {
                  staticClass: "picker",
                  attrs: { "transfer-class-name": "color-pick-w" },
                  on: { "on-open-change": _vm.handleColorChange },
                  model: {
                    value: _vm.currentColor,
                    callback: function ($$v) {
                      _vm.currentColor = $$v
                    },
                    expression: "currentColor",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.inputIcon === "ios-close-circle",
            expression: "this.inputIcon === 'ios-close-circle'",
          },
        ],
        ref: "clearRef",
        class: "iconfont iconios-close-circle",
        on: { click: _vm.clearValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }