// 国际化
import Vue from 'vue'
import VueI18n from 'vue-i18n'

const zh = require('./zh').default
const en = require('./en').default
window.BCL_lang = {
  zh, // 中文语言包
  en, // 英文语言包
}

const lang = localStorage.getItem('ark-ui-bcl-lang') || 'zh'

// 如果外部项目没有挂载i18n则主动挂载下
// 使用方或组件至少use一次。但是不能同时use，会报错
if (!('$i18n' in Vue.prototype)) {
  Vue.use(VueI18n)
}

const i18n = new VueI18n({
  locale: lang, // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: BCL_lang,
})

// 挂全局方便测试用
window.BCL_i18n = function(language) {
  i18n.locale = language
  localStorage.setItem('ark-ui-bcl-lang', language)
}

export const locale = BCL_i18n // 暴露接口给引用方使用

// 设置新语言
export const BCL_setLocaleMessage = function( locale, message) {
  i18n.setLocaleMessage(locale, message)
}

// 合并语言
export const BCL_mergeLocaleMessage = function( locale, message) {
  i18n.mergeLocaleMessage(locale, message)
}

export default i18n
