<template>
    <DropDownSelectFilter ref="DropDownSelectFilter" v-bind="propsDataCom"
                          v-on="eventFunction">
    </DropDownSelectFilter>
</template>
<script>
import Mixin from '../../mixin/DropDownFilterMixin/index';
import Config from '../../../config/nameConfig';

const prefixCls = `${Config.prefixCls}DropDownFilter`;

export default {
  name: prefixCls,
  model: {
    prop: 'value',
    event: 'on-change',
  },
  mixins: [Mixin],
  props: {
    /** 属性集合 */
    PropsData: { 
      type: Object,
      default: () => {

      }
    },
    /** 值 */
    value: {
      type: [Array, String]
    },
    /** 函数集合 */
    EventFun: { 
      type: Object,
      default: () => {

      }
    },
    /** 服务资源API */
    Url: {   
      type: Object,
      default: () => {
        return {
         autoUrl:'/p/cs/fuzzyquerybyak',
         tableUrl:'/p/cs/QueryList'
       }

      }
    },
    /** 模糊传参 */
    AutoRequest: { 
      type: Object,
      default: () => {

      }
    },
    /** 表格模糊传参 */
    TableRequest: { 
      type: Object,
      default: () => {

      }
    },
    /** 模糊数据回调 */
    autoDataMethods: { 
      type: Function
    },
    /** 表格数据回调 */
    tableDataMethods: { 
      type: Function
    }
  },
  methods: {
    render (html) {
      this.html = html;
    }
  },
  set () {
    class Component {
      constructor(prop) {
        this.props = props;
      }
      init () {
        return this;
      }
    }
  },


};
</script>

