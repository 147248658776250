<template>
  <div class="ag-icon-font">
    <template v-for="(item, index) in iconList">
      <Tooltip
        trigger="hover"
        transfer
        placement="top"
        max-width="200"
        v-if="item.text"
        :key="index"
      >
        <div
          class="ag-icon-font-pop"
          slot="content"
        >{{item.text}}</div>
        <i
          :class="`iconfont ${item.icon}`"
          :style="{color:item.color,cursor:'pointer'}"
        ></i>
      </Tooltip>
      <i
        v-if="!item.text"
        :key="index"
        :class="`iconfont ${item.icon}`"
        :style="{color:item.color}"
      ></i>
    </template>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'AgIconfont',

  props: {
    params: {
      type: Object,
    }
  },

  data() {
    return {
      icon: undefined,
      color: undefined,
      iconList: []
    }
  },

  methods: {

  },

  mounted() {
    const value = this.params.value
    if (value) {
      const data = JSON.parse(value)
      // 统一包装成数组
      if (Array.isArray(data)) {
        this.iconList = data
      } else {
        const { icon, color } = data
        this.icon = icon
        this.color = color
        this.iconList = [data]
      }
      console.log(111, this.iconList)
    }
  }
}
</script>

<style lang="scss" scoped>
.ag-icon-font {
  .ark-poptip {
    margin-right: 2px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.ag-icon-font-pop {
  word-break: break-all;
}
</style>