<template>
  <span ref="sequence">
    <template v-if="valueOfId === $t('tips.summation') || valueOfId === $t('tips.total')">{{valueOfId}}</template>
    <span
      v-else
      style="color: #0f8ee9"
      data-target-tag="rowIndex"
    >{{value || '' + parseInt(params.options.datas.start, 10)}}</span>
  </span>
</template>

<script type="text/ecmascript-6">
import i18n from '../../../utils/i18n'

const AG_SEQUENCE_COLUMN_NAME = '__ag_sequence_column_name__'

const cssFeatures = {
  hover: 'ag-syman-hover',
  imagePreviewBox: 'image-preview-box',
  tooltipBox: 'tooltip-box',
  tooltipTopBox: 'tooltip-top-box',
  textRight: 'text-right',
  attachment: 'attachment',
};

export default {
  name: 'AgSequenceComponent',

  data() {
    return {
      valueOfId: null,
    }
  },

  props: {
    params: {
      type: Object,
    }
  },

  computed: {
    value() {
      const { field } = this.params.colDef
      if(this.params.data[field] !== null && typeof this.params.data[field] === 'object') {
        return this.params.data[field].val
      } else if (field === `${AG_SEQUENCE_COLUMN_NAME}.val`) {
        // 框架的序号列逻辑
        return this.params.data[AG_SEQUENCE_COLUMN_NAME].val
      } else {
        return this.params.data[field]
      }
    }
  },

  methods: {
    mouseenter(e) {
      const {
        agGridDiv, tooltipBox, options, failIds, data
      } = this.params;

      const { target } = e;
      const offsetLeft = target.getBoundingClientRect().left - agGridDiv.getBoundingClientRect().left;
      const offsetTop = target.getBoundingClientRect().top - agGridDiv.getBoundingClientRect().top;
      const offsetBottom = agGridDiv.getBoundingClientRect().bottom - target.getBoundingClientRect().bottom;
      tooltipBox.style.display = '';
      tooltipBox.style.left = `${offsetLeft + 22}px`;
      tooltipBox.style.top = `${offsetTop - (target.offsetHeight / 2)}px`;
      if (options && options.datas && options.datas.deleteFailInfo && Object.prototype.toString.call(options.datas.deleteFailInfo) === '[object Array]') {
        const id = data.ID.val || (typeof data.ID === 'string' ? data.ID : undefined)
        tooltipBox.innerText = options.datas.deleteFailInfo[failIds.indexOf(id)].message;
      }
      const offsetBottomCalibration = tooltipBox.offsetHeight - offsetBottom - 12;
      if (offsetBottomCalibration > 0) {
        tooltipBox.style.top = `${(offsetTop - (target.offsetHeight / 2)) - (offsetBottomCalibration)}px`;
        const pseudoStyle = document.createElement('style');
        pseudoStyle.setAttribute('type', 'text/css');
        pseudoStyle.setAttribute('id', 'pseudoStyle');
        pseudoStyle.innerText = `
        .${cssFeatures.tooltipBox}::before {
            top: ${8 + (offsetBottomCalibration)}px !important;
        }`;
        document.head.appendChild(pseudoStyle);
      }
    },
    mouseleave() {
      const {
        tooltipBox
      } = this.params;

      tooltipBox.style.display = 'none';
      const pseudoStyle = document.getElementById('pseudoStyle');
      if (pseudoStyle) {
        pseudoStyle.remove();
      }
    }
  },

  mounted() {
    this.valueOfId = this.params.data && this.params.data.ID ? this.params.data.ID.val : '';
    const {
      failIds, data
    } = this.params;
      
    const id = (data.ID && data.ID.val) || (typeof data.ID === 'string' ? data.ID : undefined)
    if (failIds && failIds.indexOf(id) > -1) {
      const toolTipIcon = document.createElement('i');
      toolTipIcon.setAttribute('class', `iconfont iconbj_listwarning ${cssFeatures.hover}`);
      toolTipIcon.style.color = 'red';
      toolTipIcon.onmouseenter = this.mouseenter;
      toolTipIcon.onmouseleave = this.mouseleave;
      this.$refs.sequence.appendChild(toolTipIcon);
    }
  },

  deactivated() {
    this.mouseleave()
  },

  beforeCreate() {
    this.$t = i18n.t.bind(i18n)
  },
}
</script>

<style lang="less" scoped>
.ag-syman-hover {
  cursor: pointer;
}
</style>