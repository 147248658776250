(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"));
	else if(typeof define === 'function' && define.amd)
		define("$Bcl", ["vue"], factory);
	else if(typeof exports === 'object')
		exports["$Bcl"] = factory(require("vue"));
	else
		root["$Bcl"] = factory(root["Vue"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__8976__) {
return 