<template>
  <div>
    <DropMultiSelectFilter ref="DropDownSelectFilter"
            v-bind="propsDataCom"
            v-on="eventFunction">
    </DropMultiSelectFilter>
  </div>
</template>
<script>
  import Mixin from '../../mixin/DropDownFilterMixin/index';
  import Config from '../../../config/nameConfig';

  const prefixCls = `${Config.prefixCls}DropMultiSelectFilter`;


  export default {
    name: prefixCls,
    model: {
      prop: 'value',
      event: 'on-change',
    },
    mixins: [Mixin],
    props: {
      /** 属性集合 */
      PropsData: {  
        type: Object,
        default: () => {

        }
      },
      /** 值 */
      value: {
        type: [Array, String]
      },
      /** 函数集合 */
      EventFun: { 
        type: Object,
        default: () => {
        }
      },
      /** 服务资源API	 */
      Url: {  
        type: Object,
        default: () => {
          return {
            autoUrl: '/p/cs/fuzzyquerybyak',
            tableUrl: '/p/cs/QueryList'
          }
        }
      },
      /** 模糊传参 */
      AutoRequest: { 
        type: Object,
        default: () => {

        }
      },
      /** 表格模糊传参 */
      TableRequest: { 
        type: Object,
        default: () => {

        }
      },
      /** 模糊数据回调 */
      autoDataMethods: {  
        type: Function
      },
      /** 表格数据回调 */
      tableDataMethods: {  
        type: Function
      },
      /** 模糊查询是否支持多选 */
      filterMode: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      'on-input-value-change'(e) {
        // 模糊查询
        if (this.filterMode) {
          this.sendFilterTableMessage(1, e);
        } else {
          this.sendAutoMessage(e);
        }
        // this.newPropsData.value = e;
      },


      // filterMode模式下处理多选过滤数据
      sendFilterTableMessage(page, keyword) {
        this.pageCurrent = page;
        this.searchdata = {
          range: this.pageSize,
          startindex: (this.pageSize || 10) * (page - 1),
          ak: keyword
        };
        this.searchdata = Object.assign(this.TableRequest || {}, this.searchdata);
        this.postTableData(this.localUrl.tableUrl, this.$urlSearchParams({ searchdata: this.searchdata }))
          .then((data) => {
            // console.log('22222222222222', data)
            const da = data.datas;
            this.newPropsData.data = Object.assign({}, da);
            if (da.defaultrange) {
              this.newPropsData.pageSize = da.defaultrange;
              this.pageSize = this.newPropsData.pageSize;
            }
            this.newPropsData.totalRowCount = da.totalRowCount;
          });
      },
    }
  };
</script>

