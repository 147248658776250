export default {
  // 长的描述文案
  messages: {
    noComponent: '没有找到对应的组件',
    visibleAddInterface: '新增界面可见',
    editAddInterface: '新增界面可编辑',
    visibleEditInterface: '编辑界面可见',
    editInEditInterface: '编辑界面可编辑',
    visibleListInterface: '列表界面可见',
    invisibleAndDefault: '不可见且有默认值',
    batchModifyVisible: '批量修改可见',
  },
  // 短语文案(能复用的)
  tips: {
    alert: '提示',
    warning: '警告',
    error: '错误',
    success: '成功',
    index: '序号',
    summation: '合计',
    total: '总计',
    page: '页',
    more: '更多',
    to: '到',
    of: '共',
    next: '下一页',
    previous: '上一页',
    loading: '加载中',
    selectAll: '全选',
    search: '搜索',
    null: '空值',
    equal: '等于',
    notEqual: '不等于',
    exclude: '不包含',
    lessThanOrEqual: '小于等于',
    greaterOrEqual: '大于等于',
    inRange: '在...范围内',
    lessThan: '小于',
    greater: '大于',
    contain: '包含',
    startWith: '以...开始',
    endWith: '以...结尾',
    noData: '暂无数据',
    groupBy: '分组',
    ungroupBy: '取消分组',
    expandAll: '展开所有',
    collapseAll: '收缩所有',
    toolPanel: '工具栏',
    export: '导出',
    csvExport: '导出CSV',
    excelExport: '导出Excel',
    pinLeft: '向左固定',
    pinRight: '向右固定',
    unpin: '取消固定',
    sum: '求和',
    minimum: '最小值',
    max: '最大值',
    avg: '平均',
    average: '平均值',
    copy: '复制',
    paste: '粘贴',
    uploadAttachment: '上传附件',
    clear: '清空',
    fullscreen: '全屏',
    exitFullscreen: '退出全屏',
    exit: '退出',
    default: '默认',
    sina: '新浪',
    link: '链接',
    insertCode: '插入代码',
    insertTable: '插入表格',
    editTable: '编辑表格',
    insertVideo: '插入视频',
    editPicture: '编辑图片',
    uploadImage: '上传图片',
    webImage: '网络图片',
    find: '查询',
    yes: '是',
    no: '否',
    label: '标签',
    operator: '运算符',
    pleaseSelect: '请选择',
    button: '按钮',
    modal: '弹框',
    request: '请求',
    route: '路由',
    expand: '展开',
    fold: '折叠',
    all: '所有',
    ascending: '升序',
    add: '新增',
    modify: '修改',
    delete: '删除',
    submit: '提交',
    cancelSubmission: '取消提交',
    invalid: '作废', 
    copy: '复制',
    import: '导入', 
    export: '导出',
    print: '打印', 
    scope: '作用域',
    type: '类型',
    fontWeight: '字体粗细',
    fontStyle: '字体样式',
    textDecoration: '文本装饰',
    backgroundColor: '背景颜色',
    color: '颜色',
    rule: '规则'
  },
  // ag表格
  table: {
    currentGroup: '当前分组',
    allColumns: '所有列',
    perspectiveColumn: '透视列',
    dropGroupColumnHere: '将需要分组查询的“列”拖拽至此处',
    aggregateColumn: '聚合列',
    perspectiveMode: '透视模式',
    groupDetails: '分组详情',
    aggregateAnalysisValue: '聚合分析值',
    dropAggregatedGroupColumnHere: '将需要聚合分析的‘列’拖拽到此处',
    dropPerspectiveGroupColumnHere: '将需要透视分析的‘列’拖拽至此处',
    pinnedColumn: '固定列',
    aggregateValue: '聚合值',
    autosizeThiscolumn: '自适应当前列',
    autosizeAllColumns: '自适应所有列',
    resetColumns: '重置所有列位置信息',
    firstValue: '首值',
    lastValue: '末值',
    count: '计数',
    copyWithHeaders: '复制（含表头）',
    showAllColumns: '显示所有列',
    hideCurrentColumn: '隐藏当前列',
    columnNameConflict: '列名冲突'
  },
  docFile: {
    importingFile: '文件正在导入中',
    uploadFileLimit: '只能上传{total}个文件',
    fileCanNotUpload: '{file}不支持上传',
    continueDeleteFile: '此操作将永久删除该文件, 是否继续?',
    selectUpToFile: '最多选择{total}个文件',
    fileTooLarge: '文件内容过大',
    pictureTypeLimit: '图片类型必须是.gif,jpeg,jpg,png中的一种',
  },
  // 富文本
  wangEditor: {
    enterVideoAddress: '请输入视频地址',
    insertPictureError: '插入图片错误',
    imageVerificationFailed: '图片验证未通过',
    uploadLimitAtATime: '一次最多上传{total}张图片',
    uploadPictureTimeOut: '上传图片超时',
    uploadImageError: '上传图片发生错误',
    uploadPictureFailed: '上传图片失败',
    uploadErrorMsg: '上传图片返回结果错误，返回结果是',
    initParamsError: '错误：初始化编辑器时候未传入任何参数，请查阅文档',
    runInBrowser: '请在浏览器环境下运行',
    setTitle: '设置标题',
    textColor: '文字颜色',
    backgroundColor: '背景色',
    alignment: '对齐方式'
  },
  // 扩展属性
  extensionProperty: {
    extendAttrConfig: '扩展属性配置',
    notSupportedAttr: '不被支持的扩展属性：[{attr}]，请核实后再操作。',
    enterJSONForm: '请输入json 形式的字符串',
    enterConfig: '输入配置项',
    noConfiguration: '暂无可配置项',
    extendAttribute: '扩展属性',
    displayControl: '扩展属性显示控件',
    readWritePrintRule: '读写打印规则',
    readWriteDisplay: '读写规则显示控件',
    readWriteRule: '读写规则',
    expansionComponent: '扩展组件',
    conditionField: '条件字段',
    controlColumnField: '控制列表操作列按钮的字段',
    continueChange: '更改条件字段后，已配置的规则配置表内容将会被清空，是否继续该操作?',
    pleaseEnter: '请输入',
    separatedByCommas: '以英文逗号(,)间隔',
    JSONFormatError: 'JSON格式错误',
    displayAreaField: '展示区域字段',
    enterNameInTable: '请输入表内名称',
    sourceField: '来源字段',
    sourceFieldOptionGroup: '来源字段选项组',
    enterName: '请输入名称',
    nameInTable: '表内名称',
    dragSort: '长按可拖拽排序',
    buttonName: '按钮名称',
    enterButtonName: '请输入按钮名称',
    field: '字段',
    value: '值',
    condition: '条件',
    enterValueWithComma: '请输入匹配值（多个值时，请用逗号分隔）', 
    displayName: '显示名称',
    selectSourceField: '请选择来源字段',
    correspondence: '对应关系',
    enterOptionKey: '输入选项的键',
    enterOptionValue: '输入选项的值',
    enterSourceField: '请输入来源字段',
    correspondenceIsRequired: '请至少填写一条对应关系',
    assignValues: '对其它字段进行赋值',
    selectKnownField: '请选择已知条件字段',
    enterApi: '请输入接口地址，例如/p/c/test',
    confirmFieldAndAAddress: '请确认来源字段和接口地址是否填写',
    subtable: '子表',
    selectField: '请选择字段',
    enterValue: '请输入值',
    associateSubtable: '暂无子表可配，请先关联子表',
    associateField: '关联字段',
    contrastValue: '对比值',
    selectOtherField: '已存在相同字段，请重新选择',
    buttonField: '请输入按钮字段名',
    correspondingValue: '对应类型的值',
    optionalPrompt: '提示信息(选填)',
    triggerType: '触发事件类型',
    confirmEventAndValue: '请确认是否填写触发事件类型和对应类型的值',
    encryptionMethod: '加密方式',
    hideAll: '全隐藏',
    customHide: '自定义隐藏',
    frontHiddenDigits: '前置隐藏位数',
    endHiddenDigits: '后置隐藏位数',
    leftColumnTable: '表格左侧固定列',
    rightColumnTable: '表格右侧固定列',
    selectLeftColumn: '请选择固定在左侧的字段',
    selectRightColumn: '请选择固定在右侧的字段',
    filterBaseOnField: '根据多个字段过滤',
    ifFieldOnMainTable: '已知条件字段是否在主表上',
    knownConditionField: '已知条件字段',
    filteredFieldInForeignKey: '外键关联表中需要筛选的字段',
    ifSourceOnMainTable: '来源字段是否在主表上',
    confirmConditionAndFilter: '请确认是否填写条件字段和筛选字段',
    targetFieldEditable: '目标字段是否不可编辑',
    ifFieldRequired: '目标字段是否必填',
    clearConfig: '清除此配置项',
    selectRequiredOrUneditable: '字段是否必填和是否不可编辑至少开启其中一个',
    confirmFieldFilled: '请确认字段是否填写',
    selectQueryConditions: '请选择查询条件',
    selectTable: '请选择表',
    onlySelectType: '仅允许展示类型为select，且有关联表的字段可以进行相关配置操作。请您检查当前字段是否满足条件',
    checkCondition: '请检查条件{row}的三个选项是否都填入有效值',
    ifEncrypt: '是否加密',
    encryptDisplay: '加密后字段值即显示',
    valueFilter: '字段值过滤',
    foreignKeyRelatedField: '外键关联字段',
    associatedFieldHeader: '关联字段在表头',
    HRFold: 'HR折叠',
    expandAccordToField: '根据配置决定hr字段默认是展开还是合拢，没配置则默认为【展开】',
    dateType: '日期类型',
    beforeWithToday: '之前（含今天）',
    afterWithToday: '之后（含今天）',
    beforeWithoutToday: '之前（不含今天）',
    afterWithoutToday: '之后（不含今天）',
    controlDateRange: '控制日期型字段可选范围',
    linkCustomView: '字段链接定制界面',
    customViewAddress: '定制化界面地址',
    popupType: '弹出类型',
    associatedFieldName: '关联字段名称',
    windowName: '窗口名称',
    associatedTable: '关联表',
    routeId: '路由ID（系统内不可重复）',
    customComponent: '自定义组件（objdistype值为defined时生效）',
    listSpecifiedField: '列表界面指定字段显示链接，点击可进入对应界面。（支持tabpanle、object、popwin、customized、link）',
    virtualCalculatedColumn: '虚拟字段-计算列',
    calculationExpression: '计算表达式',
    fieldList: '字段列表',
    dateFormat: '日期格式',
    assignDescription: '赋值方式为计算列，通过其他字段及计算公式所得',
    listTranslation: '外键、虚拟字段列表翻译',
    objectTranslation: '外键、虚拟字段单对象翻译',
    fillPath: '填写转换显示格式的path',
    fieldHide: '字段隐藏显示',
    influenceColumn: '影响列',
    influenceValue: '影响值',
    displayValue: '显示值',
    ifExecHide: '是否执行隐藏逻辑',
    keepFieldPosition: '隐藏字段时保留字段的位置',
    determineShow: '根据A字段的值决定B字段是否显示(若A字段为外键类型需根据显示值匹配，则match配置为label，不配置默认为value)',
    linkageCalculation: '界面联动计算',
    relatedField: '相关字段',
    linkageField: '联动字段',
    linkageDescription: '随着A字段、B字段输入的值，即时变化显示C字段的值，C为联动字段',
    intervalConfiguration: '区间配置',
    upstreamField: '上行限制字段',
    downstreamField: '下行限制字段',
    intervalDescription: '区间字段为虚拟字段，仅支持单对象',
    ifFilterField: '是否过滤不可用数据',
    onlyISACTIVE: '只针对关联表中ISACTIVE字段可编辑，或不可编辑但该表有作废这两种情况',
    cachedTransition: '字段翻译内容是否缓存',
    cacheRule: '不配置默认为true，配置为false则不进行缓存',
    cacheTime: '字段缓存时间配置',
    cacheDescription: '字段缓存时间设置，单位为秒',
    permissionConfiguration: '列表查询条件区域字段下拉列表数据权限配置',
    dataPermissionSign: '数据权限标识',
    fieldInSourceTable: '来源表中的字段',
    getEditPermission: '是否获取编辑权限',
    permissionDescription: '列表界面查询条件区域外键字段下拉列表的数据权限',
    singlePermissionConfiguration: '单对象界面字段下拉列表数据权限配置',
    fieldInThisTable: '本表对应字段',
    singlePermissionDescription: '单对象界面外键字段下拉列表的数据权限',
    storePermissionConfiguration: '单对象关联(店仓)下拉列表数据权限配置',
    masterAuthority: '该业务表上主权限(店仓)字段',
    storePermissionDescription: '控制单对象界面关联(店仓)外键字段下拉列表的数据权限',
    listQueryRule: '列表查询规则',
    matchRule: '匹配规则',
    fullMatch: '全匹配',
    leftMatch: '左匹配',
    rightMatch: '右匹配',
    fullBlur: '全模糊',
    queryIsRequired: '查询时是否必填',
    enableVerification: '是否开启唯一校验',
    verificationDescription: '当表单新增时，填入值在文本框失去焦点时，校验是否表内已存在 / 文本框失去焦点校验唯一性',
    isdefaultField: 'isdefault字段',
    isdefaultDescription: 'isdefault字段可配置项',
    clearWhenHidden: '隐藏时清空字段值',
    clearDescription: '配置了hidecolumn扩展属性的字段，隐藏时自动清空字段内容',
    linkJump: '配置链接进行跳转',
    linkJumpDescription: '在字段的备注中，可点击链接进行跳转，例如https://www.baidu.com/。注意：字段配置中需要勾选【显示备注】',
    enterLink: '请输入链接地址',
    forbiddenNegativeSign: '控制数值型字段不能输入负号',
    forbiddenNegativeSignDesc: '控制数值型字段不能输入负号',
    uploadNumber: '图片上传数量',
    uploadNumberDesc: '控制图片上传数量',
    enterNumber: '请输入数字',
    fileUploadNumber: '文件上传数量',
    fileUploadNumberDesc: '控制文件上传数量',
    fileUploadSize: '单个文件上传大小',
    fileUploadSizeDesc: '控制单个文件上传大小',
    fileUploadFormat: '文件上传格式',
    fileUploadFormatDesc: '针对“上传附件”组件，可以控制其上传固定格式的文件。可配置多种文件格式且不区分大小写，用英文逗号间隔，例如png,crd,txt,AI,pdf,JSON。若不配置，可上传所有格式的文件。',
    enterFileFormat: '请输入文件格式',
    setInputPrompt: '设置输入框提示内容',
    setInputPromptDesc: '自定义可输入文本框中的提示内容配置后在列表界面的查询字段以及单对象界面均可生效。不配置的话，会显示系统默认的提示内容：“请输入XX”、“请选择XX”。',
    enterPrompt: '请输入提示内容',
    maximumChar: '不可编辑字段最大显示字数',
    maximumCharDesc: '控制明细列表不可编辑字段最大显示字数',
    switchApi: 'switch开关调用的接口',
    switchApiDesc: '列表配置switch组件,点击开关按钮调用的接口url',
    expansionComponentDesc: '目前支持的类型: 1.YearMonth(年月的日期组件) 2.String(支持展示纯文字和用户定制html内容的组件) 3.MonthDay(月日的日期组件)。4.InputWithSelect(条件查询输入框) 注意：1.仅【显示控件】字段值为text或者textarea时，可以配置YearMonth和MonthDay。2.字段类型为字符串类型，且赋值方式为正常赋值时，可以配置InputWithSelect',
    componentStyle: '组件style样式',
    stylePlaceholder: '例如color:red;',
    filterConfig: '过滤可配置项',
    filterConfigDesc: '字段扩展属性根据赋值方式过滤可配置项。对应配置文件中添加supportType',
    staticRule: '静态规则',
    staticRuleDesc: '根据来源字段的值，控制目标字段是否可编辑、是否必填。注意：字段是否必填和是否不可编辑至少开启其中一个',
    passMutiFields: '下拉查询传多个字段',
    passMutiFieldsDesc: '外键下拉查询接口传多个字段条件，可配置多组条件字段（意义与关联过滤相似）',
    assignValueDesc: '根据字段的值对界面其他字段进行赋值。',
    filterBaseOnFieldDesc: '外键关联字段，根据多个来源字段进行过滤。注意：当来源字段来自主表时，字段名前需要加主表表名，格式为"主表表名.字段名"',
    columnWidth: '所在表格列的宽度',
    columnWidthDesc: '配置表格字段所在列的固定宽度',
    groupFilter: '字段选项组值关联过滤',
    groupFilterDesc: '对于两个字段选项组形式的字段，目标字段的选项 根据来源字段的选项值过滤。',
    hideLabel: '是否隐藏表单项的label',
    hideLabelDesc: '控制查询区表单的label的显示和隐藏',
    listDefaultSort: '列表默认排序',
    fieldName: '字段名称',
    sortDesc: '进入列表界面之后，列表中的数据默认排序方式配置',
    globalSearchField: '全局检索字段',
    fuzzyMatching: '模糊匹配方式',
    rightBlur: '右模糊',
    leftBlur: '左模糊',
    globalAutoSearch: '全局检索自动匹配字段',
    middleField: '中间展示字段',
    leftField: '左侧字段',
    multiPopDesc: '复杂弹窗多选的配置',
    fuzzyDesc: '用户手工输入进行模糊匹配，模糊匹配的列按照顺序配置',
    foreignDropdownList: '外键下拉列表',
    sortInfo: '排序信息', 
    dropdownListColumn: '下拉列表显示列',
    dropdownListDesc: '外键关联字段，下拉窗口，指定显示的列、指定用于数据排序的列',
    enterFieldQuery: '输入键查询字段',
    fieldQueryDesc: '指定为AK2的字段可用于在列名界面进行查询',
    hiddenExportInDetail: '明细表不显示导出按钮',
    hiddenExportDesc: '表的数据由定制服务查询写入，而非标准的数据，不支持导出，则可隐藏导出按钮',
    foreignOptional: '导入模板外键字段可选择',
    foreignOptionalDesc: '配置后导入模板中外键关联字段可根据关联表中的记录下拉选择',
    buttonDisplay: '单对象按钮显示',
    displayCondition: '显示条件',
    buttonDisplayDesc: '单对象支持指定按钮在不同场景下是否显示',
    readOnlyStatus: '只读状态',
    readOnlyStatusDesc: '表的某一字段的值控制数据只读，不可以编辑',
    watermarkPriority: '水印图片优先级',
    watermarkPriorityDesc: '直接配置字段名，中越往后的字段优先级越高',
    mergeQuery: '状态合并查询',
    mergedQueryField: '合并后的查询字段名称',
    mergeField: '合并字段',
    mergeQueryDesc: '在列表界面将多个下拉选项状态字段的值合并到一个查询字段中显示',
    stylePriority: '列表样式优先级',
    stylePriorityDesc: '配置列表样式优先级，直接配置a>b>c，其中a,b,c为字段名',
    displayRows: '下拉列表显示行数',
    displayRowsDesc: '外键关联下拉列表每页显示行数',
    showAction: '新增界面可显示动作定义',
    showActionDesc: '新增单对象可显示符合显示条件的动作定义按钮',
    listCustomQuery: '列表定制查询',
    serverName: '服务名称',
    listCustomQueryDesc: '表的数据由定制服务查询写入，而非标准的数据',
    treeViewConfig: '树界面配置',
    treeView: '树界面',
    treeViewConfigDesc: '列表界面使用树界面',
    listDataPermission: '列表数据权限配置',
    listDataPermissionDesc: '控制列表界面过滤的数据，以及列表界面查询条件可以选到的数据（只检查读权限）',
    detailDataPermission: '单对象数据权限配置',
    detailDataPermissionDesc: '控制单对象界面是否可编辑（只检查写权限）',
    setNormalList: '设置成普通列表',
    setNormalListDesc: '将列表设置成普通列表（非AG）',
    enableDynamicRoute: '开启动态路由',
    enableDynamicRouteDesc: '实现效果:双击表中的明细记录，则将携带参数OBJID跳转到TABLENAME中存储的表的单对象界面',
    displayTemporaryStorageButton: '是否展示暂存按钮',
    openTemporaryStorageService: '是否开启暂存服务',
    generalApi: '通用服务接口',
    displayTemporaryStorageDesc: '实现效果:单对象界面显示【暂存】按钮，点击时前端不做必填校验',
    hideMainTable: '左右结构隐藏主表',
    hideMainTableDesc: '实现效果:只针对左右结构的单对象，且第一个子表为定制界面（明细菜单）时，可隐藏主表',
    hideImport: '隐藏导入按钮',
    hideImportDesc: '实现效果:隐藏标准列表界面的导入按钮',
    hideExport: '隐藏导出按钮',
    hideExportDesc: '实现效果:隐藏标准列表界面的导出按钮',
    storeQuery: '存储查询条件',
    storeQueryDesc: '实现效果:标准列表界面根据查询条件查询后，关闭页面再打开时，会存储上一次的查询条件一定时间（默认一天）。注意：此项配置只在前端配置中的开关关闭的时候才有效（前端开关开启的话，不配置也会存储；且此时配置false无效）。弹框多选以及状态合并查询，不会进行存储；有默认值的查询字段，即使修改条件后再打开页面，也会优先显示设置的默认值。',
    tabConfig: '列表查询tab配置',
    tabConfigDesc: '增加列表的查询tab页配置。字段生效规则: 标签名存在 + 该标签下至少有一条有效条件(同一行的 关联字段+运算符+对比值 都有值)，则视为有效数据。PS:标签组支持长按拖拽排序',
    billMarkConfig: '单据标记配置',
    billMarkConfigDesc: '增加单据标记的配置。字段生效规则: 展示区域字段存在 + 展示区域字段下至少有一条有效条件(同一行的 来源字段+来源字段选项组 都有值)，则视为有效数据。PS:每个展示区域字段组都支持长按拖拽排序',
    buttonDisableCondition: '按钮禁用条件',
    buttonDisableConditionDesc: '增加按钮禁用条件的配置',
    queryPreOperation: '列表查询前置操作',
    queryPreOperationDesc: '支持表扩展属性查询条件前配置。目前可填入的字段有：text',
    tablePinnedColumn: '表格固定列',
    tablePinnedColumnDesc: '设置表格固定列的展示。该配置返回的格式为xx|xx，其中竖线左侧的字段代表固定在左侧的列，竖线右侧的字段代表固定在右侧的列',
    subTableDisplay: '控制子表显示或隐藏',
    subTableDisplayDesc: '当主表字段等于某个值时，隐藏指定子表',
    conditionFilter: 'B字段根据A字段选择的值过滤出对应条件下的数据',
    DateShortcut: '日期快捷选项',
    DateShortcutDesc: '设置日期组件的快捷选项',
    enterDateDesc: '请输入日期描述',
    rowToColumn: '行转列',
    dynamicDisplayTable: '动态展示表头，字段名',
    sourceTable: '来源表',
    highlightConfig: '字段高亮',
    highlightConfigDesc: '满足规则的字段值将在界面上高亮显示',
    setStyle: '设置样式',
    expressionPlaceholder: '表达式示例：v>1 && v<10',
    all: '全部',
    isNull: '为空',
    isNotNull: '非空',
    defaultSelected: '默认选中',
    equal: '等于',
    resolveJson: '拆分json',
    resolveJsonDesc: '根据字段返回的json将一个字段拆分为多个显示'
  },
  // 图片预览
  previewPicture: {
    picturePreview: '图片预览'
  },
  // pop-mutiple-choice
  popMultipleChoice: {
    filterResults: '筛选结果',
    globalSearch: '全局检索',
    viewSelectedResults: '查看选中结果',
    searchResult: '查询结果',
    serialNumber: '编号',
    recordInList: '该记录已在选中列表中',
    requiredTemplateName: '模板名称不能为空',
    templateSaved: '模板保存成功',
    beSelected: '已经选中',
    selectedData: '已经选中{total}条数据',
  },
  // imageUpload
  imageUpload: {
    deleteImg: '此操作将永久删除该图片, 是否继续?',
    noPicturePreview: '暂无图片预览'
  },
  // mop-multi-select 
  mopMultiSelect: {
    filterResults: '筛选结果',
    globalSearch: '全局检索',
    viewSelectedResults: '查看选中结果',
    searchResult: '查询结果',
    serialNumber: '编号',
    recordInList: '该记录已在选中列表中',
    requiredTemplateName: '模板名称不能为空',
    templateSaved: '模板保存成功',
    beSelected: '已经选中',
    selectedData: '已经选中{total}条数据',
    multiplePop: '弹窗多选',
    moreFilters: '更多筛选',
    import: '导入', 
  },
  // 图标选择器
  iconfontPicker: {
    internal: '内置',
    color: '颜色',
    confirm: '确认',
    placeholder: '请输入icon的class名称',
    tip: '请确保已引入字体图标库'
  }
}