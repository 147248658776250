<template>
  <div :class="prefixClass">
    <div class="current-icon">
      <i
        :class="`iconfont ${pickerValue.icon}`"
        v-if="pickerValue.icon"
        :style="{color: pickerValue.color}"
      ></i>
    </div>

    <div
      class="icon-picker-placeholder"
      :class="[pickerValue.icon ? '' : 'gray']"
    >{{inputDisplayValue}}</div>

    <AutoComplete
      transfer
      :disabled="disabled"
      :icon="inputIcon"
      @on-focus="handleFocus"
      @on-blur="handleBlur"
      placeholder=""
      ref="autoRef"
    >
      <div
        class="icon-picker-panel"
        ref="iconPanelRef"
      >
        <!-- 选项 -->
        <div class="icon-picker-header">
          <div
            v-for="item in iconTypes"
            :key="item.value"
            :class="['icon-type',currentType ===item.value ? 'selected': '']"
            @click="changeType(item.value)"
          >
            {{item.text}}
          </div>
        </div>
        <div class="icon-picker-body">
          <!-- 内置图标 -->
          <div
            class="icon-list"
            v-if="currentType === 'internal'"
            :style="{width: contentWidth}"
          >
            <div
              class="icon-box"
              :class="[item.isSelected ? 'selected': '']"
              v-for="item in iconList"
              :key="item.icon"
              @click="selectIcon(item)"
            >
              <i :class="`iconfont ${item.icon}`"></i>
            </div>
          </div>
          <!-- 外部图标 -->
          <div
            class="icon-list"
            v-else
            :style="{width: contentWidth}"
          >
            <div class="icon-link-wrap">
              <a
                href="https://www.iconfont.cn/home/index"
                target="_blank"
              >
                <img
                  src="../../assets/images/iconfontLogo.png"
                  alt=""
                >
              </a>
            </div>

            <div class="icon-tip">
              *{{$t('iconfontPicker.tip')}}
            </div>

            <div class="input-icon-wrap">
              <Input
                clearable
                size="small"
                :placeholder="$t('iconfontPicker.placeholder')"
                v-model="inputValue"
              ></Input>
              <Button
                type="text"
                size="small"
                class="icon-confirm"
                @click="confirm"
              >{{$t('iconfontPicker.confirm')}}</Button>
            </div>
          </div>
        </div>

        <div class="icon-picker-footer">
          <span>{{$t('iconfontPicker.color')}}:</span>
          <ColorPicker
            v-model="currentColor"
            class="picker"
            transfer-class-name="color-pick-w"
            @on-open-change="handleColorChange"
          />
        </div>
      </div>
    </AutoComplete>

    <i
      :class="`iconfont iconios-close-circle`"
      @click="clearValue"
      v-show="this.inputIcon === 'ios-close-circle'"
      ref="clearRef"
    ></i>
  </div>
</template>

<script type="text/ecmascript-6">
import Config from '../../../config/nameConfig';
import { debounce } from '../../utils/common';
import './iconfontPicker.less'
const prefixName = `${Config.prefixCls}IconfontPicker`;
const prefixClass = `${Config.classFix}iconfont-picker`;
import i18n from '../../utils/i18n'

export default {
  name: prefixName,

  beforeCreate() {
    this.$t = i18n.t.bind(i18n)
  },

  mounted() {
    if (this.color) {
      this.pickerValue.color = this.color
      this.currentColor = this.color
    }
    if (this.icon) {
      this.pickerValue.icon = this.icon
      this.activeIcon(this.icon)
    }
    this.attachMouseMoveEvent()
  },

  // 为了让框架表单能用，绑定了一下
  model: {
    props: 'value',
    event: 'change'
  },

  props: {
    /**
     * 初始图标
     */
    icon: {
      type: String
    },
    /**
     * 初始颜色
     */
    color: {
      type: String
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean
    },
    value: {
      type: [Object, String]
    },
    /**
     * 因为r3表单需要接受字符串，这里加开关用来转换
     */
    transferJson: {
      type: Boolean
    }
  },

  data() {
    return {
      prefixClass,
      inputIcon: 'ios-arrow-down',//ios-close-circle ios-arrow-down
      currentType: 'internal',
      pickerValue: {
        icon: '',
        color: ''
      },
      currentColor: '',
      inputValue: '',
      iconTypes: [
        {
          text: this.$t('iconfontPicker.internal'),
          value: 'internal'
        },
        {
          text: 'iconfont',
          value: 'iconfont'
        },
      ],
      iconList: [{
        icon: 'icon-xitong',
        isSelected: false
      },
      {
        icon: 'icon-huiyuan',
        isSelected: false
      },
      {
        icon: 'icon-lingshou',
        isSelected: false
      },
      {
        icon: 'icon-zhongduan',
        isSelected: false
      },
      {
        icon: 'icon-yunwei',
        isSelected: false
      },
      {
        icon: 'icon-md-cube',
        isSelected: false
      },
      {
        icon: 'icon-md-pricetags',
        isSelected: false
      },
      {
        icon: 'icon-diamond',
        isSelected: false
      },
      {
        icon: 'icon-circle',
        isSelected: false
      },
      {
        icon: 'icon-a-five-pointedsta',
        isSelected: false
      },
      {
        icon: 'icon-hexagon',
        isSelected: false
      },
      {
        icon: 'icon-rhombus',
        isSelected: false
      },
      {
        icon: 'icon-triangle',
        isSelected: false
      },
      {
        icon: 'icon-RC_logo',
        isSelected: false
      },
      {
        icon: 'icon-bj_correct',
        isSelected: false
      },
      {
        icon: 'icon-bj_error',
        isSelected: false
      },
      {
        icon: 'icon-tiaozhuan',
        isSelected: false
      },
      {
        icon: 'icon-yunque',
        isSelected: false
      },
      {
        icon: 'icon-bojun',
        isSelected: false
      },
      {
        icon: 'icon-BOS_logo',
        isSelected: false
      },
      {
        icon: 'icon-upgrade',
        isSelected: false
      },
      {
        icon: 'icon-versions',
        isSelected: false
      },
      {
        icon: 'icon-sort1',
        isSelected: false
      },
      {
        icon: 'icon-turnto1',
        isSelected: false
      },
      {
        icon: 'icon-field',
        isSelected: false
      },
      {
        icon: 'icon-bj_search',
        isSelected: false
      },
      {
        icon: 'icon-ios-arrow-up',
        isSelected: false
      },
      {
        icon: 'icon-ios-arrow-down',
        isSelected: false
      },
      {
        icon: 'icon-md-close',
        isSelected: false
      },
      ],
      contentWidth: '200px',
      selectData: {}
    }
  },

  computed: {
    inputDisplayValue() {
      const placeholder = this.placeholder ? this.placeholder : this.$t('tips.pleaseSelect')
      return this.pickerValue.icon ? this.pickerValue.icon : placeholder
    }
  },

  methods: {
    clearValue(e) {
      if (this.disabled) {
        return
      }
      this.pickerValue.icon = ''
      this.pickerValue.color = ''
      this.currentColor = ''
      this.activeIcon('')
      this.updateValue()
    },

    // 绑定鼠标事件
    attachMouseMoveEvent() {
      window.addEventListener('mousemove', this.recordMouse)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('mousemove', this.recordMouse)
      })
    },

    // 记录鼠标在哪个元素上
    recordMouse(e) {
      this._mousemoveDom = e.target

      const autoRef = this.$refs.autoRef.$el
      const placeholder = this.placeholder ? this.placeholder : this.$t('tips.pleaseSelect')
      const clearRef = this.$refs.clearRef
      // 有值且悬浮在输入框时。显示清除图标
      if ((autoRef.contains(e.target) || clearRef.contains(e.target)) && this.inputDisplayValue !== placeholder && !this.disabled) {
        this.inputIcon = 'ios-close-circle'
      } else {
        // 恢复之前的图标
        this.inputIcon = this._popVisible ? 'ios-arrow-up' : 'ios-arrow-down'
      }
    },

    handleFocus(e) {
      this.$nextTick(() => {
        e.target.blur()
        this.contentWidth = e.target.offsetWidth + 'px' // 防止每次点击弹框，弹框宽度会边长
      })
    },

    handleBlur() {
      const iconPanelRef = this.$refs.iconPanelRef
      const popNode = iconPanelRef.parentNode.parentNode
      popNode.style.overflow = 'visible' // 使colorPicker组件能超出容器显示
      this.observerDropDown()
    },

    // 监听弹框显示隐藏
    observerDropDown() {
      const iconPanelRef = this.$refs.iconPanelRef
      const popNode = iconPanelRef.parentNode.parentNode
      const autoRef = this.$refs.autoRef.$el

      const observer = new MutationObserver((mutations) => {
        const lastChange = mutations[mutations.length - 1]
        const isHide = lastChange.target.style.display === 'none'
        const currentDom = this._mousemoveDom
        const isInside = iconPanelRef.contains(currentDom) || autoRef.contains(currentDom)
        if (isHide && !isInside) {
          // console.log('隐藏')
          this._popVisible = false
          if (this.inputIcon !== 'ios-close-circle') {
            this.inputIcon = 'ios-arrow-down'
          }
          this.updateValue()
          observer.disconnect()

        } else {
          // console.log('显示')
          this._popVisible = true
          if (this.inputIcon !== 'ios-close-circle') {
            this.inputIcon = 'ios-arrow-up'
          }
        }
      })
      observer.observe(popNode, {
        attributes: true,
        attributeFilter: ['style']
      })
    },

    // 提交value更新
    updateValue: debounce(function () {
      if (this.transferJson) { // 这段逻辑主要给框架用
        if (!this.pickerValue.icon && !this.pickerValue.color) {
          this.$emit('change', '')
          return
        }
        this.$emit('change', JSON.stringify(this.pickerValue))
      } else {
        this.$emit('change', this.pickerValue)
      }
    }, 0),

    // 重新激活内置图标的高亮
    activeIcon(value) {
      this.iconList.forEach(item => {
        if (item.icon === value && item.isSelected) {
          item.isSelected = false // 点击相同图标则取消激活
          this.pickerValue.icon = ''
        } else {
          item.isSelected = item.icon === value
        }
      })
    },

    changeType(type) {
      this.currentType = type
      this.activeIcon(this.pickerValue.icon)
    },

    selectIcon(current) {
      this.pickerValue.icon = current.icon
      this.activeIcon(current.icon)
    },

    confirm() {
      this.pickerValue.icon = this.inputValue
      this.inputValue = ''
    },

    handleColorChange() {
      this.pickerValue.color = this.currentColor
    }
  }
}
</script>

<style lang="scss" scoped>
</style>