<template>
  <span>
    <span
      :class="[cssFeatures.hover]"
      :style="style"
      data-target-tag="customerUrlText"
      :title="params.value || ''"
    >{{params.value || ''}}</span>
  </span>
</template>

<script type="text/ecmascript-6">
const cssFeatures = {
  hover: 'ag-syman-hover',
  imagePreviewBox: 'image-preview-box',
  tooltipBox: 'tooltip-box',
  tooltipTopBox: 'tooltip-top-box',
  textRight: 'text-right',
  attachment: 'attachment',
};

export default {
  name: 'AgCustomerUrlComponent',

  props: {
    params: {
      type: Object,
    }
  },

  data() {
    return {
      cssFeatures,
      style: {
        textDecoration: 'underline',
        color: '#0F8EE9',
      }
    }
  },

  methods: {
    setStyle() {
      const { colDef, mode, data } = this.params
      const highlight = colDef.webconf && colDef.webconf.highlight
      if (highlight && highlight.scope !== 'form' && !colDef.key_group && mode !== 'commonTable') {
        highlight.rules.forEach(rule => {
          const func = new Function('v', `return ${rule.operator}`)
          if (func(data[colDef.colId])) {
            Object.keys(rule.style).forEach(k => {
              // 排除textDecoration,fontWeight,fontStyle的默认值
              if (rule.style[k] && rule.style[k] !== 'normal' && rule.style[k] !== 'none') {
                this.style[k] = rule.style[k]
              }
            })
          }
        })
      }
    }
  },

  mounted() {
    this.setStyle()
  }
}
</script>

<style lang="less" scoped>
.ag-syman-hover {
  cursor: pointer;
}
</style>